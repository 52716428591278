import { defineComponent, toRef } from 'vue';
import LargePicker from './LargePicker.class';
export default defineComponent({
    props: {
        allowPastDates: { type: Boolean, default: false },
        pastYearsRange: Number,
        futureYearsRange: Number,
        selectedDate: String
    },
    emits: [
        'dateChanged'
    ],
    setup: (props, { emit }) => {
        const component = new LargePicker(emit, props.allowPastDates, toRef(props, 'pastYearsRange'), toRef(props, 'futureYearsRange'), toRef(props, 'selectedDate'));
        return {
            selectedYear: component.selectedYear,
            selectedMonth: component.selectedMonth,
            canNavigateBack: component.canNavigateBack,
            canNavigateForward: component.canNavigateForward,
            displayMonths: component.displayMonths,
            decrementYear: component.decrementYear.bind(component),
            incrementYear: component.incrementYear.bind(component),
            monthSelected: component.monthSelected.bind(component),
        };
    }
});
