import { defineComponent, onMounted, toRef } from 'vue';
import LargePicker from '../LargePicker/LargePicker.vue';
import Monthpicker from './Monthpicker.class';
export default defineComponent({
    components: {
        LargePicker
    },
    props: {
        allowPastDates: { type: Boolean, default: false },
        pastYearsRange: Number,
        futureYearsRange: Number,
        label: String,
        defaultMonth: String,
        defaultYear: String
    },
    emits: [
        'dateChanged'
    ],
    setup: (props, { emit }) => {
        const component = new Monthpicker(emit, props.allowPastDates, toRef(props, 'pastYearsRange'), toRef(props, 'futureYearsRange'), props.defaultMonth, props.defaultYear);
        onMounted(component.mounted.bind(component));
        return {
            inputText: component.inputText,
            label: props.label,
            isInvalid: component.isInvalid,
            errorMessage: component.errorMessage,
            dateTextInput: component.dateTextInput,
            nativePicker: component.nativePicker,
            largePicker: component.largePicker,
            selectedDate: component.selectedDate,
            minDate: component.minDate,
            maxDate: component.maxDate,
            showLargePicker: component.showLargePicker,
            showNativePicker: component.showNativePicker,
            monthClicked: component.monthClicked.bind(component),
            onDateSelected: component.onDateSelected.bind(component),
            onTextInput: component.onTextInput.bind(component),
            onEnterClicked: component.onEnterClicked.bind(component),
        };
    }
});
