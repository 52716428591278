import { computed, ref, watch } from 'vue';
export default class LargePicker {
    emit;
    allowPastDates;
    pastYearsRange;
    futureYearsRange;
    selectedDate;
    constructor(emit, allowPastDates, pastYearsRange, futureYearsRange, selectedDate) {
        this.emit = emit;
        this.allowPastDates = allowPastDates;
        this.pastYearsRange = pastYearsRange;
        this.futureYearsRange = futureYearsRange;
        this.selectedDate = selectedDate;
        if (selectedDate.value) {
            this.setMonthAndyearFromDate(selectedDate.value);
        }
        watch(selectedDate, (newValue) => {
            if (newValue) {
                this.setMonthAndyearFromDate(newValue);
            }
        });
    }
    selectedYear = ref(new Date().getFullYear());
    selectedMonth = ref(new Date().getMonth() + 1);
    maxYear = computed(() => {
        if (!this.futureYearsRange.value) {
            return null;
        }
        const currentYear = new Date().getFullYear();
        return currentYear + this.futureYearsRange.value;
    });
    minYear = computed(() => {
        const currentYear = new Date().getFullYear();
        if (!this.allowPastDates) {
            return currentYear;
        }
        if (!this.pastYearsRange.value) {
            return null;
        }
        return currentYear - this.pastYearsRange.value;
    });
    canNavigateBack = computed(() => {
        if (!this.minYear.value) {
            return true;
        }
        return this.selectedYear.value > this.minYear.value;
    });
    canNavigateForward = computed(() => {
        if (!this.maxYear.value) {
            return true;
        }
        return this.selectedYear.value < this.maxYear.value;
    });
    displayMonths = computed(() => {
        const months = [];
        for (let i = 1; i <= 12; i++) {
            const date = new Date(this.selectedYear.value, i - 1, 1);
            months.push({
                number: i,
                name: date.toLocaleString('en-us', { month: 'short' }),
                isDisabled: this.isMonthDisabled(i)
            });
        }
        return months;
    });
    decrementYear() {
        if (!this.canNavigateBack.value) {
            return;
        }
        this.selectedYear.value = this.selectedYear.value - 1;
    }
    incrementYear() {
        if (!this.canNavigateForward.value) {
            return;
        }
        this.selectedYear.value = this.selectedYear.value + 1;
    }
    monthSelected(month) {
        if (month.isDisabled) {
            return;
        }
        this.selectedMonth.value = month.number;
        this.emit('dateChanged', {
            month: this.selectedMonth.value.toString().padStart(2, '0'),
            year: this.selectedYear.value.toString()
        });
    }
    setMonthAndyearFromDate(dateString) {
        const date = new Date(dateString);
        this.selectedMonth.value = date.getMonth() + 1;
        this.selectedYear.value = date.getFullYear();
    }
    isMonthDisabled(month) {
        if (!this.minYear.value || this.canNavigateBack.value) {
            return false;
        }
        const currentMonth = new Date().getMonth() + 1;
        return month < currentMonth;
    }
}
